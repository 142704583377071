@import 'commons/_variables';
@import 'commons/_mixins';

.popup-overlay {
    backdrop-filter: brightness(60%) blur(4px);
}

.modal {
    font-size: 12px;
    background-color: white;
    padding: 20px 30px;
    max-width: 500px;
    border-radius: 20px;
    margin: 5px 15px;
}

.modal > .header_modal {
    @include text(18px, 22px, $black, normal);
    width: 100%;
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 5px 5px 20px;
}

.modal > .content {
    width: 100%;
    padding: 10px 5px;
    max-height: 500px;
    overflow: auto;
}

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 6px;
    top: 0;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}