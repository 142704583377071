@import "_variables";

@mixin text($fs, $lh, $color: $white, $fw: normal) {
	font-family: Montserrat;
	font-style: normal;
	font-size: $fs;
	line-height: $lh;
	color: $color;
	font-weight: $fw;
}

@mixin flex {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  @content;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  @content;
}

@mixin flexPack {
  text-align: left;
  align-items: flex-start;
  @content;
}

@mixin links($opacity: 65%, $tr: .3s all) {
	&:hover {
		opacity: $opacity;
	}
	cursor: pointer;
	text-decoration: none;
	transition: $tr;
	@content;
}

@mixin wh($w: auto, $h: auto) {
	width: $w;
	height: $h;
}

@mixin dropShadow {
	-webkit-box-shadow: 0px 2px 6px 1px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 0px 2px 6px 1px rgba(34, 60, 80, 0.2);
		box-shadow: 0px 1px 6px 1px rgba(34, 60, 80, 0.2);
}

@mixin dropShadow-xl {
	-webkit-box-shadow: 0 4px 15px rgba(0,0,0,.3), 0 0 10px rgba(0,0,0,.3);
}

@mixin rotateImg {
	transform: rotate3d(.5,-.866,0,15deg) rotate(-1deg);
	transition: .3s all;
	&:hover {
		transform: rotate3d(.5,.866,0,15deg) rotate(1deg);
	}
}

@mixin tl($t: auto, $l:auto) {
	top: $t;
	left: $l;
}

@mixin btn($color: $white, $cursor: default, $br...) {
	background-color: $color;
	cursor: $cursor;
	border-radius: $br;
}

@mixin add($pos, $z, $m...) {
	position: $pos;
	z-index: $z;
	margin: $m;
}

@mixin radius($br, $p...) {
	border-radius: $br;
	padding: $p;
}
