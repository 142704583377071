@import 'commons/_variables';
@import 'commons/_mixins';

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'swiper/css/scrollbar';

// Common styles

* {
    box-sizing: border-box;
}

body {
    font-family: Montserrat;
    font-style: normal;
    background-color: $white;
    position: relative;
    min-height: 100vh;
}

.yandexMap {
    max-width: 100%;
    width: 100%;
    border-radius: 20px;
}

.yandexMapMain {
    max-width: 500px !important;
}

.dropShadow {
    @include dropShadow;
}

.dropShadow-xl {
    @include dropShadow-xl;
}

.rotateImg {
    @include rotateImg;
}

.scale {
    transition: 0.3s all;
    &:hover {
        transform: scale(1.05);
    }
}

.py-10 {
    padding: 10px 0;
}

.py-15 {
    padding: 15px 0;
}

.px-10 {
    padding: 0 10px;
}

.mt-4 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.scroll-smooth {
    scroll-behavior: smooth;
}
.block {
    display: block !important;
}
.required_field {
    @include text(11px, 13px, $lightBlack, 500);
    font-family: monospace;
    margin: 0 0 10px;
}

.popup-search {
    margin-top: -53% !important;
}

.cookie {
    @include text(14px, 16px, $lightWhite, 500);
    @include flexColumn;
    box-shadow: 0px 0px 8px 1px rgba(255, 255, 255, 0.35);
    z-index: 100;
    align-items: center;
    background-color: $lightBlack;
    border-radius: 15px;
    padding: 15px 0;
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: max-content;
    span {
        width: 70%;
    }
}

.buttonToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 101;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $lightGray;
    animation: smoothAppearance 0.3s ease-in forwards;
    &:hover {
        transform: scale(1.1);
    }
    @keyframes smoothAppearance {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    &:after {
        content: '▲';
    }
}

.displayNone {
    display: none;
}

.flex {
    @include flex;
}

.flexColumn {
    @include flexColumn;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
}

.wh100 {
    @include wh(100%);
}

.wh50 {
    @include wh(50%);
}

.mt-10 {
    margin-top: 10px;
}

.gap-1 {
    gap: 0.5rem;
}

.gap-2 {
    gap: 1rem;
}

.gap-3 {
    gap: 2rem;
}

.title {
    @include text(20px, 24px, $black, bold);
}

.wrapper {
    width: 100%;
    max-width: 1650px;
    margin: 0 auto;
}

.empty-flex {
    height: 0;
    width: 250px;
    border: 0;
    margin: 0 15px;
    text-decoration: none;
}

.up {
    padding: 18px;
    background: $blue;
    position: fixed;
    right: 45px;
    bottom: 45px;
    z-index: 999;
    display: none;
}

.preloaderPage {
    margin: 50px 10px;
}

.priceRed {
    @include text(20px, 25px, red, bold);
    text-decoration: line-through !important;
    margin-left: 15px;
}

.mainLink {
    @include links;
    color: $linkBlue;
}

.default-text {
    @include text(16px, 20px, $black, normal);
}

.button {
    @include text(16px, 20px, $black, 500);
    display: inline-block;
    border: 0;
    padding: 10px 20px;
    border-radius: 12px;
    transition: 0.3s all;
    text-decoration: none;
    &-little {
        @include text(14px, 16px, $black, 500);
        padding: 5px 10px;
        border-radius: 8px;
    }
    &:hover {
        opacity: 0.75;
        transform: scale(0.95);
    }
    &:disabled {
        cursor: not-allowed;
        &:hover {
            transform: scale(1);
        }
    }
}

.productsItemsTop {
    @include flex;
    @include wh(100%);
    position: absolute;
    left: 0px;
    padding: 15px;
    align-items: center !important;

    &-item {
        width: 62px;
        text-align: center;
    }
}

.discountPercentage,
.newItem {
    border-radius: 10px;
    z-index: 99;

    span {
        @include text(14px, 18px, $white, normal);
        margin: auto;
        padding: 7px 13px;
    }
}

.discountPercentage {
    background: $red;
}

.newItem {
    background: $green;
}

.action {
    color: $white !important;
    background: $green !important;
    font-weight: 700 !important;
}

.blackButton {
    color: $white !important;
    background: $lightBlack !important;
    font-weight: 700 !important;
}

.addToFavorites {
    @include links(80%);
    opacity: 30%;
    top: 20px;
    right: 20px;
    z-index: 9;

    img {
        @include wh(25px);
    }

    &-active {
        opacity: 80%;

        &:hover {
            opacity: 50%;
        }
    }
}

.default-link {
    @include links;
}

// Search module styles

.search {
    position: relative;
    width: 100%;

    &-wrapper {
        &__mobile {
            display: none;
        }
    }

    input {
        @include text(16px, 18px, $lightBlack, normal);
        background: url('../img/search.svg') no-repeat 18px center;
        padding: 13px 5px 13px 45px;
        border: 2px solid $lightGray;
        background-color: $white;
        border-radius: 12px;
        width: 100%;
        transition: 0.2s all;
        cursor: text;

        &::placeholder {
            color: $lightBlack;
        }

        &:focus {
            border-color: $darkGray;
        }
    }

    &__autocomplete {
        @include dropShadow;
        text-align: left;
        position: absolute;
        left: 0;
        top: 60px;
        background-color: $white;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
        z-index: 100;
        max-height: 300px;
        height: auto;
        overflow: auto;
        border-radius: 12px;

        &-item {
            @include text(16px, 18px, $black, normal);
            @include flex;
            justify-content: flex-start;
            padding: 10px 20px;
            transition: 0.2s all;
            text-decoration: none;

            &:hover {
                background-color: $lightGray;
                cursor: pointer;
            }
        }
    }
}

//filters styles

.filters {
    position: relative;
    max-width: 1000px;
    width: 50%;
    text-align: left;
    margin: 15px 0;

    &__title {
        @include links;
    }

    &__autocomplete {
        position: absolute;
        left: 0;
        top: 35px;
        background-color: $white;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
        z-index: 100;
        max-height: 300px;
        height: auto;
        overflow: auto;

        &-item {
            @include text(16px, 18px, $black, normal);
            padding: 10px 20px;
            transition: 0.2s all;

            &_subitem {
                padding: 3px 5px;
                transition: 0.2s all;

                &:hover {
                    background-color: $lightGray;
                    cursor: pointer;
                    border-radius: 100px;
                }
            }
        }
    }
}

// Home Page styles

.homePage {
    text-align: left;
    align-items: center;
}

.margin-wrapper {
    margin: 60px 0 !important;
}

// Main styles

.main {
    flex: 1;
    &__wrapper {
        margin: 0 50px;

        &-titleBlock {
            margin: 40px 0 20px;

            p {
                @include text(28px, 32px, $black, 700);
            }
        }

        &-back {
            button {
                margin-right: 20px;
            }

            p {
                @include text(28px, 32px, $black, 700);
            }
        }

        &-sortedBy {
            margin-bottom: 50px;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;

            &-details {
                align-items: flex-start;
                margin-top: 10px;

                &__blur {
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100vh;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.6);
                    backdrop-filter: blur(10px);
                    z-index: 500;
                }

                &__container {
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100vh;
                    width: auto;
                    max-width: 80%;
                    z-index: 501;
                    background-color: $darkWhite;
                    padding: 20px 20px 140px;
                    justify-content: flex-start;
                }
            }

            &_select {
                @include text(16px, 18px, $black, normal);
                appearance: none;
                padding: 0 10px;
                width: 150px;
                cursor: pointer;
                border: 0;
            }

            &_title {
                @include text(18px, 22px, $black, bold);
                cursor: pointer;
            }
            p {
                @include text(18px, 22px, $black, bold);
            }

            &_price {
                &-low {
                }

                &-high {
                    transform: rotate(180deg);
                }
            }

            &_age,
            &_price {
                img {
                    transition: 0.3s all;

                    &:not(.price) {
                        margin-right: 50px;
                    }
                }
            }

            .active {
                transform: rotate(180deg);

                &:not(.price) {
                    margin-right: 50px;
                }
            }
        }
    }

    &__checkout {
        margin: 80px 0 50px;

        &-buttonsGroup {
            margin: 0 -10px;
        }

        &-price {
            p {
                @include text(18px, 20px, $black, 700);

                span {
                    text-decoration: underline;
                }
            }
        }

        &-button {
            @include text(14px, 17px, $black, normal);
            @include radius(15px, 10px 20px);
            border: 2px solid $lightGray;
            box-sizing: border-box;
            background: transparent;
            margin: 0 10px;
        }
    }

    &__catsName {
        @include text(18px, 22px, $black, bold);
        padding-bottom: 20px;
        height: 55px;
    }

    &__item {
        @include wh(250px);
        @include dropShadow;
        position: relative;
        border-radius: 15px;
        background-color: $darkWhite;
        overflow: hidden;
        margin-bottom: 30px;

        :not(:last-child) {
            @include flexPack;
        }

        h4 {
            @include text(24px, 29px, $black, bold);
            min-height: 58px;
            display: grid;
            align-items: end;
            text-align: left;

            span {
                @include text(20px, 25px, red, bold);
                text-decoration: line-through;
            }
        }

        &-photoBlock {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: relative;
        }

        &-photo {
            position: absolute;
            object-fit: cover;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            min-width: 100%;
            min-height: 100%;
            height: 250px;
            transition: 0.3s all;
            cursor: pointer;
            box-shadow: 0px 1px 13px -2px rgba(34, 60, 80, 0.2);

            &:hover {
                transform: translate(-50%, 0) scale(1.05);
            }
        }

        &-action {
            @include btn($blue, pointer, 0 0 15px 15px);
            transition: 0.3s all;

            &-added {
                filter: brightness(50%);
                cursor: pointer;
            }

            &-text {
                @include text(16px, 20px, $white, bold);
                text-decoration: none;
                padding: 15px 5px;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: $darkBlue;
            }
        }

        &-wrapper {
            padding: 29px;
            margin-top: 250px;

            a {
                text-decoration: none;
            }
        }

        &-group {
            padding-bottom: 10px;
        }
    }

    &__showMore {
        @include text(14px, 17px, $black, normal);
        @include radius(10px, 20px 85px);
        &:hover {
            transform: scale(1.05);
        }
        transition: 0.3s all;
        background: transparent;
        border: 2px solid $lightGray;
        box-sizing: border-box;
        margin: 0 5px 40px;
    }
}

.group__info {
    margin: 10px 0;

    :first-child {
        margin-bottom: 3px;
    }

    p {
        @include text(12px, 15px, $black, normal);
    }

    &-last {
        @include text(12px, 15px, red, normal);
        display: inline;
        margin-left: 5px;
    }

    &-question {
        @include text(14px, 17px, red, 600);
        margin-top: 5px;
    }

    &-soldOut {
        @include text(16px, 18px, red, 500);
        display: block;
    }

    &-bold {
        @include text(16px, 20px, $black, bold);
        font-weight: bold !important;
        display: inline;
    }

    &-left {
        img {
            @include wh(5px);
            margin-right: 5px;
        }
    }
}

//Basket Page styles

.basketPage {
    &__container {
        margin: -20px 0;

        &-item {
            @include dropShadow;
            margin: 20px 0;
            border-radius: 20px;
        }

        &-photoBlock {
            @include wh(230px);
            position: relative;
        }

        &-desc {
            @include wh(50%);
            align-items: flex-start;
            text-align: left;
            flex-grow: 6;
            background: $lightGray;
            border-radius: 0 20px 20px 0;
            padding: 29px;

            a {
                text-decoration: none;
            }
        }

        &-sizes {
            p {
                @include text(12px, 15px, $black, normal);
            }

            span {
                @include text(14px, 18px, $black, bold);
            }

            margin-bottom: 40px;
        }

        &-title {
            @include text(20px, 25px, $black, bold);
            padding-bottom: 20px;

            span {
                @include text(14px, 18px, $gray, bold);
                margin-left: 8px;
            }
        }

        &-photo {
            height: 205px;
            transition: 0.3s all;
            cursor: pointer;
            border-radius: 20px 0 0 20px;
        }

        &-price {
            @include text(24px, 29px, $black, bold);
        }

        &-buy {
            @include btn($blue, pointer, 15px);
            @include text(16px, 20px, $white, bold);
            text-decoration: none;
            padding: 15px 5px;
            transition: 0.3s all;
        }

        &-buttonsGroup {
            flex-grow: 1;
        }
    }
}

// favorites styles

.favoritesPage {
    margin-bottom: 50px;

    &__container {
        margin: -20px 0;
        padding: 50px 0 20px;

        &-item {
            margin: 20px 0;
        }

        &-photoBlock {
            @include wh(230px);
            position: relative;
        }
    }
}

//ErrorsComponents styles

.error404page {
    padding: 100px 10px;

    &__img {
        @include wh(50px);
        cursor: pointer;
        margin: 10px;
    }

    p {
        @include text(18px, 22px, $black);

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &__textBlock {
        margin-top: 40px;
    }
}

//order styles

.order-status-help {
    padding: 30px 0 10px;
    border-top: 1px gray solid;

    &_span {
        background-color: $lightGray;
        border-radius: 100px;
        width: 10px;
        padding: 10px;
    }

    &_active {
        background-color: $green;
        border-radius: 100px;
        width: 10px;
        padding: 10px;
    }

    &_line {
        height: 1px;
        width: 30px;
        background-color: $lightGray;
        &_active {
            height: 1px;
            width: 30px;
            background-color: $green;
        }
    }
}

.order-adminStatus {
    &_blue {
        background: lightskyblue;
    }
    &_lightGreen {
        background: lightgreen;
    }
    &_yellow {
        background: #ffff63;
    }
    &_orange {
        background: #ffb729;
    }
    &_gray {
        background: lightgray;
    }
    &_purple {
        background: #ad86ff;
    }
    &_green {
        background: #6fff6f;
    }
    &_red {
        background: #ff4444;
    }
}

.order-colorStatus {
    &_blue {
        color: deepskyblue;
    }
    &_lightGreen {
        color: lightgreen;
    }
    &_yellow {
        color: yellow;
    }
    &_tiffany {
        color: #0abab5;
    }
    &_orange {
        color: orange;
    }
    &_gray {
        color: gray;
    }
    &_purple {
        color: rebeccapurple;
    }
    &_green {
        color: limegreen;
    }
    &_red {
        color: red;
    }
}

.order-status {
    border-radius: 50px;
    color: $white !important;
    display: block;
    height: 100%;
    padding: 3px 0;
    &_blue {
        background: deepskyblue;
    }
    &_lightGreen {
        background: lightgreen;
    }
    &_yellow {
        background: yellow;
        color: $black !important;
    }
    &_orange {
        background: orange;
    }
    &_gray {
        background: gray;
    }
    &_purple {
        background: rebeccapurple;
    }
    &_green {
        background: limegreen;
    }
    &_red {
        background: red;
    }
}

.fix-width {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table {
    min-width: 1550px;
    margin-bottom: 0;
    max-width: none;
    &-admin {
        min-width: 2000px;
    }
}

// Scrollbar Override
.scroll-inner {
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparentize(#ccc, 0.7);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparentize(#ccc, 0.5);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
}

.personalOrderList {
    align-items: flex-start;
    padding: 20px 0;

    &__arrowsIcon {
        @include wh(25px, 25px);
        animation: 1s linear infinite alternate move;
    }

    &__title {
        @include text(20px, 25px, $black, 700);
        margin-bottom: 20px;
    }

    &__table {
        &-row {
            align-items: flex-start;
            padding: 5px 7px;
        }

        &-mainRow {
            background-color: $lightGray;
        }

        &-date {
            min-width: 110px;
        }

        &-art {
            min-width: 120px;
        }

        &-column {
            @include text(18px, 22px, $black, normal);
            padding: 5px;

            span {
                font-weight: bold;
            }

            &-little {
                flex: 15%;
            }

            &-medium {
                flex: 20%;
            }

            &-big {
                flex: 35%;
            }
        }
    }
}

.orderСlarification {
    max-width: 350px;
    margin: 0 auto 25px;
    align-items: flex-start;

    &-text {
        text-align: left;
        margin-bottom: 5px;

        span {
            @include text(16px, 20px, $black, 600);
            text-decoration: underline;
        }
    }

    &-title {
        @include text(20px, 24px, $black, normal);
        margin-bottom: 10px;
    }
}

.orderInfo {
    margin: 0 auto 25px;
    align-items: flex-start;

    &-row {
        text-align: left;
        align-items: flex-start;
        background-color: $lightGray;
        padding: 5px 7px;

        &:nth-child(2n) {
            background-color: transparent;
        }
    }

    &-column {
        flex: 50%;

        span {
            font-weight: 600;
        }
    }
}

.userCard {
    margin: 0 auto;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    text-align: left;
    align-items: flex-start;
}

.personal-discount {
    align-items: flex-start;
    border-radius: 12px;
    padding: 20px;
}

//Product Card start

.productCard {
    align-items: flex-start;
    margin: 50px 0;

    &__mainBlock {
        margin-top: 50px;
        align-items: flex-start;
        flex-wrap: nowrap;
        text-align: left;
        gap: 30px;

        &-photo {
            position: relative;
            width: 50%;

            .slick-next {
                right: 10px;
            }

            .slick-prev {
                left: 10px;
                z-index: 10;
            }

            &_img {
                width: 100%;
                height: 100%;
                max-height: min-content;
            }
        }

        &-about {
            width: 50%;
            &_name {
                @include text(28px, 38px, $black, bold);
                margin-bottom: 10px;
            }

            &_art {
                @include text(14px, 18px, $gray, bold);
                display: inline-block;
                margin-bottom: 15px;
            }

            &_desc {
                @include text(18px, 22px, $black, normal);
                margin-bottom: 20px;
            }

            &_sizes {
                @include text(18px, 22px, $black, normal);
                margin-bottom: 20px;

                span {
                    @include text(18px, 22px, $black, bold);
                    font-weight: bold !important;
                }
            }

            &_features {
                @include text(18px, 22px, $black, normal);
                margin-bottom: 20px;

                span {
                    @include text(18px, 22px, $black, bold);
                    font-weight: bold !important;
                }
            }

            &_price {
                @include text(24px, 29px, $black, bold);
                min-height: 58px;
                display: grid;
                align-items: end;

                span {
                    @include text(20px, 25px, red, bold);
                    text-decoration: line-through;
                }
            }
        }
    }

    &__addToBasket {
        @include text(14px, 17px, $black, normal);
        @include radius(15px, 10px 20px);
        border: 2px solid $lightGray;
        box-sizing: border-box;
    }

    &__update {
        margin-top: 15px;

        &-buttonsGroup {
            justify-content: space-between;
            background-color: $darkGrey;
            gap: 15px;
            padding: 12px;
            border-radius: 20px;
            width: max-content;
        }
    }
}

.main-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    flex-direction: column;
    gap: 1.5rem;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $lightBlack;
    z-index: 999;

    &__img {
        width: 100%;
        max-width: 130px;
    }
}

.infoBlock {
    background-color: $dirtyGrey;
    padding: 18px 0;
    overflow-x: hidden;
    height: 36px;
    p {
        @include text(15px, 17px, $white, normal);
    }
}

.marquee {
    display: flex;
    animation: marquee 30s linear infinite;
}

.marquee p {
    min-width: max-content;
    margin-right: 100%;
    white-space: nowrap;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

.hotDeals__title {
    img {
        @include wh(30px, 30px);
        margin-right: 10px;
    }
}

//About block styles

.about {
    margin: 50px 0;
    flex-wrap: nowrap;
    text-align: left;
    align-items: flex-start;

    &__text {
        @include text(18px, 22px, $black, normal);

        &-paragraph {
            margin-bottom: 15px;
        }

        &-title {
            @include text(22px, 25px, $black, bold);
        }

        &-accent {
            font-weight: 600;
        }

        &-arrow {
            @include text(18px, 22px, $white, 500);
            border: 0;
            margin-top: 10px;
            display: inline-block;
            padding: 7px 18px;
            border-radius: 15px;
            background-color: $lightBlack;
        }
    }

    &__images-container {
        width: 50%;
    }

    &__image {
        width: 100%;
        max-width: 100% !important;
        border-radius: 25px;
        height: 333px;
        object-fit: cover;
    }

    &__photo {
        @include wh(500px);
        border-radius: 20px;
        &-wrapper {
            border-radius: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-flow: dense;
            gap: 1rem;
        }
    }
}

.categories_block {
    padding: 50px 0 25px;
}

// personalInfo style

.personalInfo {
    align-items: flex-start;

    &__title {
        @include text(20px, 25px, $black, 700);
    }

    &__text {
        @include text(18px, 22px, $black, normal);
        margin-bottom: 10px;
    }

    &__bold {
        font-weight: 600;
    }

    &__gray {
        color: $gray;
    }
}

//Profile style

.profile {
    &__wrapper {
        align-items: flex-start;
        padding-bottom: 50px;
    }

    &__logout {
        @include links;
        border: 2px solid $lightGray;
        border-radius: 15px;
        padding: 10px 20px;
    }

    &__goToAdmin {
        @include text(15px, 17px, $white, 500);
        border: 2px solid $lightGray;
        border-radius: 15px;
        padding: 10px 20px;
    }

    &__block {
        align-items: flex-start;

        &-info {
            @include text(16px, 18px, $black, normal);
            margin-bottom: 15px;
        }
    }

    &__menu {
        background-color: $darkWhite;
        padding: 7px 0;
        border-radius: 15px;

        .active {
            background-color: $lightBlack;
            padding: 10px 18px;
            color: $white;
        }
    }
}

//SizePopup

.sizePopup {
    &-text {
        @include text(14px, 17px, $black, normal);
        text-align: left;

        span {
            color: orange;
        }

        &:not(:first-child) {
            margin-top: 5px;
        }
    }

    &-img {
        @include wh(300px, 300px);
        margin: auto;
    }
}

//FORM

.form {
    &__wrapper {
        margin: 20px 0 10px;
        gap: 0.75rem;
    }

    &__title {
        @include text(28px, 32px, $lightBlack, 600);
        margin-bottom: -10px;
    }

    &__subtitle {
        @include text(20px, 24px, $lightBlack, 500);
    }
}

.formBlock {
    position: relative;
    max-width: 350px;
    margin-bottom: 20px;
}

.input {
    @include text(18px, 20px, $black, normal);
    border: 1.5px solid $gray;
    padding: 10px 20px;
    border-radius: 12px;

    &:focus {
        border-color: $lightBlack;
    }

    &:focus + label {
        color: $lightBlack;
    }

    &:focus,
    &:not(:placeholder-shown) {
        & + label {
            top: -8px;
            transform: scale(0.81);
        }
    }

    &-error {
        border-color: red !important;
    }

    &__label {
        @include text(15px, 17px, $darkGray, normal);
        position: absolute;
        padding: 0 4px;
        left: 15px;
        top: 13px;
        background-color: $white;
        transition: 0.1s all;
        pointer-events: none;

        &-radio {
            @include text(15px, 17px, $darkGray, normal);
            position: relative;
            margin-bottom: 10px;
            display: block;
        }

        &-error {
            color: red !important;
        }

        &-focus {
            color: $lightBlack;
        }

        &-success {
            color: $green;
        }
    }

    &__error {
        @include text(14px, 16px, red, 500);
        display: inline-block;
        margin-top: 5px;
    }

    &__success {
        @include text(14px, 16px, $green, 500);
        display: inline-block;
        margin-top: 5px;
    }

    &__link {
        @include text(16px, 19px, $black, normal);
        margin-bottom: 15px;
    }
}

//Category Links main

.categoryLinks {
    @include flex;
    gap: 1rem;

    .active {
        background-color: $lightBlack !important;
        color: $white !important;
        border-color: $lightBlack !important;
    }
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.column {
    flex: 1;
    margin-right: 20px;
}

.column:last-child {
    margin-right: 0;
}

.desktop-menu {
    @include dropShadow;
    border-radius: 12px;
    position: absolute;
    align-items: center;
    background-color: $white;
    padding: 20px 50px;
    width: max-content;
    top: 80%;
    margin: 10px;
}

.findUs {
    margin: 37px 0;
    align-items: flex-start;

    &__titleBlock {
        margin-bottom: 37px;
        text-align: left;

        &-title {
            @include text(28px, 32px, $black, 700);
        }

        &-img {
            @include wh(40px, 40px);
            margin-left: 10px;
        }
    }

    &__mainBlock {
        @include text(18px, 22px, $black);
        align-items: flex-start;
        text-align: left;
        flex-wrap: nowrap;
        margin-bottom: 20px;

        &-img {
            @include rotateImg;
            max-width: 500px;
            width: 100%;
            border-radius: 20px;
            margin: 5px 15px;
        }

        &-paragraph {
            margin-bottom: 10px;
        }

        &-accent {
            color: $red;
            font-weight: bold;
        }
    }
}

//Checkbox start

.checkbox-btn {
    @include text(13px, 15px, $black, normal);
    display: inline-block;
    margin: 0 5px 0 0;
    user-select: none;
    position: relative;
    transition: 0.2s all;

    &_input {
        z-index: -1;
        opacity: 0;
        display: block;
        width: 0;
        height: 0;

        &:checked + span {
            background: #ffe0a6;
        }
    }

    &_span {
        border-radius: 10px;
        background: #f5f5f5;
        display: inline-block;
        cursor: pointer;
        padding: 0 10px;
        line-height: 30px;
        transition: background 0.2s ease;
    }
}

.CustomButtons {
    display: block;
    background: #6ebbd3;
    border-radius: 50px;
}

//admin

.admin {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 2rem;
    padding: 80px 10px;

    &__wrapper {
        margin: 40px 0;
    }

    &__titleBlock {
        margin-bottom: 37px;

        &-title {
            @include text(24px, 30px, $black, 700);
            margin-left: 15px;
        }

        &-img {
            @include wh(40px, 40px);
        }
    }

    &__block {
        @include links;
        @include text(18px, 22px, $black, normal);
        @include dropShadow;
        border-radius: 20px;
        padding: 20px;
        margin: 10px;
        width: 200px;

        &-soon {
            background-color: #f2f2f2;
            border: 2px dashed #c3c3c3;
        }

        :hover {
            scale: 90%;
            transition: 0.3s all;
        }

        span {
            font-size: 35px;
            margin-top: 20px;
            height: 44px;
        }
    }

    &__form {
        &-image {
            justify-content: center;
            gap: 1em;
            margin-bottom: 10px;
        }

        &-block {
            select {
                @include wh(100%);
                text-align: center;
            }
            position: relative;
            max-width: 500px;
            margin-bottom: 25px;
        }

        &-imgItem {
            margin-bottom: 15px;
            input {
                width: 90%;
            }
            &_button {
                @include links;
                background: $lightGray;
                border: 1px solid $black;
                width: 38px;
                height: 38px;
            }
        }

        &-group {
            flex-wrap: wrap !important;
            justify-content: center;
        }

        &-label {
            @include text(15px, 17px, $darkGray, normal);
            position: absolute;
            padding: 0 4px;
            left: 10px;
            top: 11px;
            background-color: $white;
            transition: 0.1s all;
            pointer-events: none;

            &_radio {
                @include text(15px, 17px, $darkGray, normal);
                position: relative;
                margin-bottom: 10px;
                display: block;

                &-variant {
                    @include text(15px, 17px, $black, 500);
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    padding: 5px;

                    input {
                        margin-right: 3px;
                    }
                }
            }
        }

        &-error {
            @include text(14px, 15px, red, normal);
            position: absolute;
            left: 0;
            top: 42px;
            right: 0;
        }

        &-reg {
            @include text(16px, 19px, $black, normal);
            margin-bottom: 15px;
        }
    }
}

@keyframes move {
    from {
        margin-left: -25px;
    }
    to {
        margin-left: 25px;
    }
}

.image-container {
    position: relative;
    cursor: pointer;
    img {
        width: 70px;
        height: auto;
        transition: opacity 0.5s;
        border: 1.5px solid $gray;
        border-radius: 12px;
    }

    &:hover {
        img {
            opacity: 0.5;
        }

        .cross {
            opacity: 1;
        }
    }

    .cross {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        opacity: 0;
        transition: opacity 0.5s;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 15px;
            height: 30px;
            width: 2px;
            background-color: #000;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.sizes {
    &__container {
        padding: 10px;
        border-radius: 12px;
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.image-placeholder {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.itemDisabledBlock {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: $gray;
    opacity: 0.3;
    cursor: not-allowed;
}

.newYear {
    &__lights {
        background: url('../img/lights.png') top left repeat-x;
        background-size: 150px;
        position: absolute;
        height: 40px;
        margin-top: -20px;
    }
}

.infoLayout {
    margin: 37px 0;
    align-items: flex-start;

    &__title {
        @include text(28px, 32px, $black, 700);
        margin-bottom: 30px;
        text-align: left;
    }

    &__container {
        align-items: flex-start;
        text-align: left;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        max-width: 1300px;

        &-img {
            @include dropShadow;
            height: 100%;
            width: 100%;
            border-radius: 20px;
            object-fit: cover;

            &:hover {
                cursor: zoom-in;
            }
        }

        &-paragraph {
            @include text(18px, 22px, $black);
        }

        &-accent {
            @include text(18px, 22px, $lightBlack);
            font-weight: 600;
        }

        &-link {
            color: blue;
            text-decoration: none;
        }
    }
}

.scalingImage {
    width: 100vw;
    height: 100vh;
    background-color: $lightBlack;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;

    &__img {
        margin: auto;
        height: 100%;
        width: 100%;
        object-fit: contain;
        cursor: zoom-out;
    }
}

.sorting {
    @include text(18px, 22px, $black, normal);
    position: relative;
    &__modal {
        @include dropShadow;
        border-radius: 8px;
        padding: 10px 15px;
        background-color: $white;
        position: absolute;
        top: -30%;
        right: -100%;
        z-index: 100;

        &-button {
            @include links;
            @include text(18px, 22px, $black, normal);
            border: 0;
            background-color: transparent;

            &:disabled {
                color: $gray;
            }
        }
    }
}

.promocodePage {
    &-emoji {
        @include text(100px, 120px, $black, normal);
        @include links;
        cursor: pointer;
        &:hover {
            transform: scale(0.98);
        }
    }
    gap: 2rem;
    &-hint {
        @include text(16px, 20px, $darkGray, normal);
    }
    &-title {
        @include text(18px, 22px, $black, normal);
    }
    &-subtitle {
        @include text(36px, 48px, $black, bold);
        position: relative;
        z-index: 1;
        padding: 5px 15px;
        margin: 10px 0;
    }
    &-sale {
        @include text(22px, 24px, $black, normal);
    }
    &-card {
        padding: 20px 50px;
        border-radius: 12px;
        position: relative;
    }
    &-line {
        height: 0.5px;
        background: $black;
    }
    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(8px);
        z-index: -1;
        opacity: 0.6;
        border-radius: 25px;
    }
}

.orderCity {
    @include text(16px, 18px, $black, normal);
    text-align: center;
    &-title {
        @include text(18px, 20px, $black, 600);
    }
    &-accent {
        font-weight: 600;
    }
}

.grid-container {
    display: grid;
    margin: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    span {
        @include text(22px, 25px, $white, 500);
        z-index: 10;
    }
}

.grid-item {
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: #6ebbd3;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 10px;

    &-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.3s all;

        &_container {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #1f2021;
            opacity: 0.5;
        }
    }
}

.grid-item-1 {
    grid-column: span 1;
}

.grid-item-2 {
    grid-column: span 2;
}

.grid-item-3 {
    grid-column: span 3;
}

.category {
    cursor: pointer;
    text-decoration: none;
    &:hover {
        > .grid-item-img {
            transform: scale(1.1);
        }
    }
}

.drop-down-list {
    &__category {
        @include text(19px, 22px, $black, 500);
        @include flex;
        text-decoration: none;
    }
    &__element {
        width: 100%;
        padding: 10px 20px;
        border: 2px solid $lightGray;
        border-radius: 15px;
        cursor: pointer;

        &-active {
            background-color: rgb(241, 241, 241);
            border-color: transparent;
        }
    }
    &__info {
        @include flexColumn;
        align-items: flex-start;
        padding: 10px 0;
        gap: 1rem;
    }
    &-arrow {
        width: 30px;
        transition: transform 0.3s ease-in-out;

        &__rotate {
            transform: rotate(180deg);
        }
    }
}

.filtersMobile {
    display: none;
}

.filters-desc {
    display: flex;
}

.badge {
    @include text(12px, 13px, $white);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10%;
    right: -3px;
}

@keyframes fadeOutScale {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.animate-close {
    animation: fadeOutScale 0.3s linear;
}
