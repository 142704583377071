@import '../commons/_variables';
@import '../commons/_mixins';

.header {
    // background: url('../../img/header/bg.png') no-repeat fixed bottom center;
    // background-size: cover;
    background-color: $lightBlack;
    z-index: 200;
    position: relative;

    // &__blur {
    //     background: rgba(31, 32, 33, 0.75);
    // }

    &__middle {
        &-position {
            gap: 2rem;
        }
    }

    &__wrapper {
        @include flexPack;
        padding: 40px 0;
        margin: 0 50px;
    }

    &__link {
        @include links;
        @include text(18px, 22px, $white, 400);
        padding: 15px 0;
        text-align: left;

        &-title {
            @include links;
            @include text(19px, 22px, $lightBlack, 400);
            text-align: left;
        }

        &-subtitle {
            @include links;
            @include text(16px, 22px, $lightBlack, 400);
            text-align: left;
        }
    }

    &__nav {
        @include flex;
        gap: 2rem;
        &-link {
            @include links;
            @include text(18px, 22px, $white, 400);

            img {
                @include wh(35px, 35px);
                fill: #111;
            }
        }
        &-button {
            display: inline-block;
            margin: 0;
        }

        &-basket {
            background: url('../../img/basket.svg') no-repeat left;
            padding-left: 32px;
        }
    }

    &__items {
        padding: 25px 0px 3px;
        text-align: right;

        &-title {
            @include text(38px, 46px, $white, bold);
        }
        &-subtitle {
            @include text(30px, 40px, $white, bold);
        }
        &-order {
            @include links;
            @include text(30px, 40px, $black, bold);
            background-color: white;
            border-radius: 10px;
            padding: 0 8px 2px;
        }
    }

    &__right-wrapper {
        display: none;
    }
    &__right-burger {
        label {
            display: flex;
            flex-direction: column;
            width: 30px;
            cursor: pointer;
        }

        label span {
            background: $white;
            border-radius: 10px;
            height: 3px;
            margin: 3px 0;
            z-index: 105;
            transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }

        span:nth-of-type(1) {
            width: 50%;
        }

        span:nth-of-type(2) {
            width: 100%;
        }

        span:nth-of-type(3) {
            width: 75%;
        }

        input[type='checkbox']:checked ~ label {
            span {
                background: $white;
            }
        }

        input[type='checkbox'] ~ nav {
            background: white;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            z-index: 100;
            transition: 0.3s;
            transition-delay: 0.3s;
            overflow: hidden;
            box-shadow: 0 10px 15px -1px rgba(0, 0, 0, 0.2);
            > ul {
                text-align: center;
                position: absolute;
                top: 20%;
                left: 20%;
                right: 20%;
                > li {
                    opacity: 0;
                    transition: 0.3s;
                    transition-delay: 0s;
                    > a,
                    p {
                        @include text(22px, 27px, $black, 500);
                        @include links;
                        display: block;
                        padding: 30px;
                    }
                }
            }
        }

        input[type='checkbox']:checked ~ nav {
            height: 100%;
            transition-delay: 0s;
            > ul {
                > li {
                    opacity: 1;
                    transition-delay: 0.3s;
                }
            }
        }

        input[type='checkbox'] {
            display: none;
        }

        input[type='checkbox']:checked ~ label > span:nth-of-type(1) {
            transform-origin: bottom;
            transform: rotatez(45deg) translate(4px, 0px);
        }

        input[type='checkbox']:checked ~ label > span:nth-of-type(2) {
            transform-origin: top;
            transform: rotatez(-45deg);
        }

        input[type='checkbox']:checked ~ label > span:nth-of-type(3) {
            transform-origin: bottom;
            width: 50%;
            transform: translate(13px, -5px) rotatez(45deg);
        }
    }

    &__logo {
        padding-right: 20px;

        a {
            z-index: 105;
        }

        &-hat {
            position: absolute;
            top: -26%;
            left: 6%;
        }

        &-img {
            @include wh(80px, 80px);
            border-radius: 100px;
            margin-right: 20px;
        }

        &-about {
            align-items: flex-start;
        }

        &-title {
            @include text(24px, 29px, $white, 500);
        }

        &-subtitle {
            @include text(18px, 22px, $white);
        }

        &-phoneNumber {
            margin-top: 5px;
            @include text(16px, 17px, $white, 500);
        }
        &-openingHours {
            margin-top: 5px;
            @include text(14px, 15px, $white, 300);
        }
    }
}

.mobile-menu {
    &__block {
        position: absolute;
        top: 100%;
        right: 0;
        height: calc(100vh - 100%);
        width: 80%;
        background-color: $white;
        overflow-y: auto;
    }
    &__category {
        @include text(19px, 22px, $black, 500);
        @include flex;
        text-decoration: none;
    }
    &__link {
        @include text(17px, 19px, $black, normal);
        @include flex;
        justify-content: flex-end;
        gap: 0.5rem;
        text-decoration: none;
    }
    &__content {
        padding: 7px 0 0;
    }
    &__wrapper {
        padding: 15px 15px 140px;
        text-align: right;
    }
    &__element {
        width: 100%;
        padding: 0 20px;
    }
    &__blur {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: calc(100vh - 100%);
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(10px);
    }
    &__line {
        height: 1px;
        background-color: $gray;
        width: 100%;
    }
    &-arrow {
        width: 30px;
        transition: transform 0.3s ease-in-out;

        &__rotate {
            transform: rotate(180deg);
        }
    }
}
