@import 'commons/_mixins';

@media screen and (max-width: 1500px) {
    .header {
        &__link {
            font-size: 16px;
        }

        &__nav {
            gap: 1.5rem;
        }
    }
}

@media screen and (max-width: 1295px) {
    .header {
        &__middle {
            &-position {
                gap: 1.5rem;
            }
        }
        &__nav {
            gap: 1rem;
        }
    }
}

@media screen and (max-width: 1235px) {
    .productCard__update-buttonsGroup {
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 1160px) {
    .productCard {
        margin: 0;
    }
    .fixedTopContainer {
        margin-top: 20px;
    }

    .categories_block {
        padding: 0;
    }

    .margin-wrapper {
        margin: 20px 0 !important;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .main__wrapper,
    .header__wrapper {
        margin: 0 20px;
        padding: 30px 0;
    }

    .header__right-position {
        display: none;
    }
    .header__right-wrapper {
        display: flex;
    }

    .header {
        &__middle {
            &-position {
                display: none;
            }
        }
    }

    .about {
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 35px 0 0;
        &__photo {
            width: 100%;
            &-wrapper {
                margin: 0 0 30px;
            }
        }
        &__text {
            margin-bottom: 30px;
        }

        &__images-container {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1100px) {
    .contacts {
        width: 100% !important;
    }

    .yandexMapMain {
        max-width: 100% !important;
    }

    .footer {
        &__links {
            @include flex;
        }
    }

    .filtersMobile {
        display: flex;

        &__wrapper {
            flex-wrap: nowrap;
            overflow: auto;
        }
    }

    .filters-desc {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .header__logo-title,
    .header__logo-subtitle,
    .header__items-title {
        display: none;
    }
    .header__items-subtitle {
        @include text(15px, 20px, $white, bold);
        margin: 10px 0 5px;
    }
    .header__items-order {
        @include text(15px, 20px, $black, bold);
    }

    .header__logo {
        &-openingHours {
            margin-top: 5px;
            @include text(12px, 15px, $white, 300);
        }
    }

    // .mobile-menu {
    //     &__line {
    //         display: none;
    //     }
    // }

    .admin {
        @include flexColumn;
        padding: 30px 10px;
    }

    .fixedTopContainer {
        flex-direction: column;
    }

    .search {
        width: 100%;
    }

    .productCard__update-buttonsGroup {
        flex-direction: row;
        width: max-content;
    }

    .header {
        position: sticky;
        top: 0;
        width: 100%;
    }

    .header__logo img {
        @include wh(45px, 45px);
        margin-right: 15px;
    }

    .main__wrapper-titleBlock {
        margin-top: 25px;
    }

    .about__text {
        margin-top: 15px;
    }

    .header__wrapper {
        padding: 15px 0;
    }

    .header__items-title {
        @include text(26px, 32px, $white, bold);
    }

    .productCard__mainBlock {
        flex-direction: column;
        &-photo {
            width: 100%;
            margin: 0 0 50px;
        }
        &-about {
            width: 100%;
        }
    }

    .infoLayout {
        &__container {
            &-paragraph {
                @include text(16px, 20px, $black);
            }

            &-accent {
                @include text(18px, 22px, $lightBlack);
                font-weight: 600;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .main__content-products,
    .favoritesPage__container {
        display: grid;
        grid-template-columns: 165px 165px 165px 165px;
    }

    .group__info {
        margin: 3px 0;
    }

    .main {
        &__content > &__item {
            margin: 0 1px 20px;
        }
        &__item {
            width: 165px;
            border-radius: 10px;
            &-photo {
                height: 165px;
            }
            &-wrapper {
                padding: 10px;
                margin-top: 165px;
            }
            &-group {
                flex-direction: column;
                padding-bottom: 5px;
            }
        }
        &__catsName {
            @include text(14px, 18px, $black, bold);
            padding-bottom: 5px;
            height: 41px;
        }
        h4 {
            @include text(18px, 22px, $black, bold);
            min-height: 44px;
            span {
                @include text(16px, 18px, red, bold);
            }
        }
    }

    .productsItemsTop {
        padding: 10px;

        &-item {
            width: 48px;
        }
    }

    .discountPercentage,
    .newItem {
        span {
            @include text(12px, 15px, $white, normal);
            padding: 4px 9px;
        }
    }

    .discountPercentage {
        left: 10px;
    }

    .newItem {
        top: 10px;
        left: 50%;
    }

    .addToFavorites {
        top: 10px;
        right: 10px;

        img {
            @include wh(20px);
        }
    }

    .findUs {
        &__mainBlock {
            flex-direction: column;
            width: 100%;
            &-img {
                margin: 25px 0 0;
            }
        }
    }

    .footer__wrapper {
        align-items: center;
        flex-direction: column-reverse;
        &-infoBlock {
            width: 100%;
            align-items: center;
            p {
                text-align: center;
            }
        }
    }
    .footer__links-block {
        width: 100%;
        align-items: center;
        a {
            text-align: center;
        }
    }
}

@media screen and (max-width: 735px) {
    .main__content-products,
    .favoritesPage__container {
        display: grid;
        grid-template-columns: 165px 165px 165px;
        justify-content: space-evenly;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: dense;
    }

    .search {
        &-wrapper {
            &__mobile {
                @include flex;
                width: 100%;
                margin: 0 0 30px;
            }
        }
    }

    .header__right-search {
        display: none;
    }
}

@media screen and (max-width: 660px) {
    .productCard__update-buttonsGroup {
        flex-direction: column;
        width: 100%;
    }

    .main__wrapper-sortedBy {
        margin-bottom: 20px;
    }

    .main__checkout {
        flex-direction: column;
        &-price {
            flex-direction: column-reverse;
            margin-bottom: 20px;
        }
        &-buttonsGroup {
            @include flexColumn;
            flex-direction: column-reverse;
        }
        &-button {
            margin-bottom: 15px;
        }
    }

    .basketPage__container {
        flex-direction: row;
        &-photo {
            border-radius: 20px 20px 0 0;
        }
        &-photoBlock {
            width: 100%;
        }
        &-desc {
            border-radius: 0;
            width: 100%;
        }
        &-title {
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            span {
                margin: 0;
            }
        }
        &-price {
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            span {
                margin: 0;
            }
        }
        &-buttonsGroup {
            width: 100%;
            background-color: $darkWhite;
            border-radius: 0 0 20px 20px;
            padding: 0 10px 15px;
        }
        &-item {
            @include dropShadow;
            flex-direction: column;
            width: 250px;
        }
    }

    .main__wrapper {
        //div {
        //  flex-wrap: nowrap;
        //}
        &-titleBlock {
            @include flexColumn;
        }
        &-sortedBy {
            flex-direction: column;
            align-items: flex-start;
            &_select {
                padding: 0;
            }
        }
    }
}
@media screen and (max-width: 570px) {
    .main__content-products,
    .favoritesPage__container {
        display: grid;
        grid-template-columns: 165px 165px;
    }

    .orderCity {
        @include text(14px, 15px, $black, normal);
    }

    .basketPage__container {
        display: grid;
        grid-template-columns: 165px 165px;
        justify-content: space-evenly;
        &-desc {
            padding: 10px;
        }
        &-sizes {
            margin-bottom: 10px;
        }
        &-photo {
            height: 165px;
        }
        &-title {
            @include text(14px, 18px, $black, bold);
            height: 85px;
            padding-bottom: 10px;
        }
        &-item {
            width: 165px;
        }
    }

    .about {
        &__photo {
            &-wrapper {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .hotDeals__title {
        display: block;
    }

    .infoLayout {
        margin-top: 20px;
        &__title {
            margin-bottom: 20px;
        }
    }

    .about__text {
        margin-top: 5px;
    }

    .main__wrapper-titleBlock p {
        @include text(24px, 28px, $black, 700);
    }

    .footer__links-block {
        margin-bottom: 25px;
        p {
            margin-bottom: 15px;
        }
        a {
            margin-bottom: 5px;
        }
    }

    .homePage__links {
        @include text(16px, 18px, $black, 500);
        padding: 0 10px;
        border-radius: 10px;
    }
}

//Телефоны

@media screen and (max-width: 414px) {
    .main__content-products,
    .favoritesPage__container,
    .basketPage__container {
        justify-content: space-between;
    }

    .favoritesPage__container {
        flex-direction: column;
    }

    .personalOrderList {
        &__table {
        }
    }

    .personalInfo {
        &__title {
            @include text(18px, 22px, $black, 700);
        }

        &__text {
            @include text(14px, 17px, $black, normal);
        }
    }

    .promocodePage {
        &-subtitle {
            font-size: 26px;
            line-height: 32px;
        }
    }

    .about {
        width: 100%;
    }

    .admin__titleBlock {
        @include flexColumn;
        align-items: flex-start;
        &-title {
            margin: 15px 0 0 0;
        }
    }
}
