@import '../commons/_variables';
@import '../commons/_mixins';

.footer {
  background-color: $lightBlack;

  &__wrapper {
    align-items: flex-start;
    padding: 80px 0;
    margin: 0 50px;

    &-infoBlock {
      @include wh(30%);
      align-items: flex-start;
      text-align: left;
      p {
        @include text(0.88rem, 1.5rem, $white, normal);
      }
      a {
        @include text(0.88rem, 1.5rem, $white, normal);
        @include links;
      }
    }
    &-links {
      @include wh(65%);
      align-items: flex-start;
    }
  }
  &__logo {
    @include links;
    margin-bottom: 32px;
    justify-content: center;
    img {
      @include wh(40px, 40px);
      border-radius: 100px;
      margin-right: 20px;
    }
  }
  &__links-block {
    align-items: flex-start;
    margin: 5px 10px 35px;
    p {
      @include text(0.625rem, 0.75rem, $white, bold);
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    a {
      @include links(75%);
      @include text(0.88rem, 1.35rem, $lightGray, normal);
      margin-bottom: 10px;
      text-align: left;
    }
    &:last-child {
      a {
        margin-bottom: 20px;
        @include links(75%);
        @include flex;
        @include text(0.88rem, 1.35rem, $lightGray, normal);
        img {
          margin-right: 5px;
        }
        p {
          @include text(0.625rem, 0.75rem, $lightGray, normal);
          text-transform: uppercase;
          &:not(:first-child) {
            margin: 0;
          }
        }
      }
    }
  }
  h5 {
    @include text(1.2rem, 1.5rem, $white, bold);
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}


