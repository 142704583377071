@font-face {
    font-family: 'Copperplate Gothic Bold';
    src: url('fonts/copper_bold.eot');
    src:
        url('fonts/copper_bold.eot') format('embedded-opentype'),
        url('fonts/copper_bold.woff2') format('woff2'),
        url('fonts/copper_bold.woff') format('woff'),
        url('fonts/copper_bold.ttf') format('truetype'),
        url('fonts/copper_bold.svg#copper_bold') format('svg');
    font-style: normal;
    font-weight: normal;
}

/* montserrat-regular - latin_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/montserrat-v15-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src:
        local('Montserrat Regular'),
        local('Montserrat-Regular'),
        url('fonts/montserrat-v15-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('fonts/montserrat-v15-latin_cyrillic-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('fonts/montserrat-v15-latin_cyrillic-regular.woff') format('woff'),
        /* Modern Browsers */ url('fonts/montserrat-v15-latin_cyrillic-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('fonts/montserrat-v15-latin_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/montserrat-v15-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src:
        local('Montserrat Bold'),
        local('Montserrat-Bold'),
        url('fonts/montserrat-v15-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('fonts/montserrat-v15-latin_cyrillic-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('fonts/montserrat-v15-latin_cyrillic-700.woff') format('woff'),
        /* Modern Browsers */ url('fonts/montserrat-v15-latin_cyrillic-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('fonts/montserrat-v15-latin_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
    src:
        local(''),
        url('fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('fonts/montserrat-v25-latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('fonts/montserrat-v25-latin-500.woff') format('woff'),
        /* Modern Browsers */ url('fonts/montserrat-v25-latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
    src:
        local(''),
        url('fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('fonts/montserrat-v25-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('fonts/montserrat-v25-latin-600.woff') format('woff'),
        /* Modern Browsers */ url('fonts/montserrat-v25-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('fonts/montserrat-v25-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
    src:
        local(''),
        url('fonts/montserrat-v25-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('fonts/montserrat-v25-latin_cyrillic-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('fonts/montserrat-v25-latin_cyrillic-500.woff') format('woff'),
        /* Modern Browsers */ url('fonts/montserrat-v25-latin_cyrillic-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('fonts/montserrat-v25-latin_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/montserrat-v25-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
    src:
        local(''),
        url('fonts/montserrat-v25-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('fonts/montserrat-v25-latin_cyrillic-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('fonts/montserrat-v25-latin_cyrillic-600.woff') format('woff'),
        /* Modern Browsers */ url('fonts/montserrat-v25-latin_cyrillic-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('fonts/montserrat-v25-latin_cyrillic-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* bodoni 72 - latin_cyrillic */
@font-face {
    font-family: 'Bodoni 72';
    font-style: normal;
    font-weight: bold;
    src: url('fonts/bodoni-72-bold.ttf'); /* IE9 Compat Modes */
    src:
        local(''),
        url('fonts/bodoni-72-bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* didot - latin_cyrillic */
@font-face {
    font-family: 'Didot';
    font-style: normal;
    font-weight: bold;
    src: url('fonts/didot_bold.otf'); /* IE9 Compat Modes */
    src:
        local(''),
        url('fonts/didot_bold.otf') format('truetype'); /* Safari, Android, iOS */
}

/*@font-face {*/
/*        font-family: "MuseoSansLight";*/
/*        src: url("fonts/MuseoSansLight/MuseoSansLight.eot");*/
/*        src: url("fonts/MuseoSansLight/MuseoSansLight.eot?#iefix")format("embedded-opentype"),*/
/*        url("fonts/MuseoSansLight/MuseoSansLight.woff") format("woff"),*/
/*        url("fonts/MuseoSansLight/MuseoSansLight.ttf") format("truetype");*/
/*        font-style: normal;*/
/*        font-weight: normal;*/
/*}*/

/*@font-face {*/
/*        font-family: "MuseoSansMedium";*/
/*        src: url("fonts/MuseoSansMedium/MuseoSansMedium.eot");*/
/*        src: url("fonts/MuseoSansMedium/MuseoSansMedium.eot?#iefix")format("embedded-opentype"),*/
/*        url("fonts/MuseoSansMedium/MuseoSansMedium.woff") format("woff"),*/
/*        url("fonts/MuseoSansMedium/MuseoSansMedium.ttf") format("truetype");*/
/*        font-style: normal;*/
/*        font-weight: normal;*/
/*}*/

/*@font-face {*/
/*        font-family: "MuseoSansBlack";*/
/*        src: url("../Fonts/MuseoSansBlack.eot");*/
/*        src: url("../Fonts/MuseoSansBlack.eot?#iefix")format("embedded-opentype"),*/
/*        url("../Fonts/MuseoSansBlack.woff") format("woff"),*/
/*        url("../Fonts/MuseoSansBlack.ttf") format("truetype");*/
/*        font-style: normal;*/
/*        font-weight: normal;*/
/*}*/
