$white: #FFFFFF;
$black: #000000;
$lightBlack: #1F2021;
$darkGrey: #383838;
$dirtyGrey: #333333;
$darkWhite: #F3F3F3;
$blue: #6EBBD3;
$darkBlue: #65a9bf;
$linkBlue: #0000FF;
$red: #E52D2D;
$green: #1ab834;


$cream: #F9F6F1;
$lightBrown: #D7B399;
$red: #D84033;
$silver: #F4F4F4;
$lightGray: #CBCFD4;
$lightWhite: #E5E5E5;


$gray: #9D9D9D;
$lightGray: #f3f3f3;
$darkGray: #5C5C5C;
$lightBlue: #E7F6FF;